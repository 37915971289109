<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <div class="card-body">
          <form @submit.prevent="saveProductionCost" class="row">
            <div class="col-md-3" style="overflow-y: scroll; max-height: 120vh">
              <ul class="nav nav-tabs flex-column" id="myTab" role="tablist">
                <li class="nav-item ml-4" v-for="(p, i) in products" :key="i">
                  <a
                    class="nav-link"
                    :class="{
                      active: p.id === calc.product_id
                    }"
                    :id="`tab${p.id}`"
                    data-toggle="tab"
                    :href="`#pack${p.id}`"
                    role="tab"
                    :aria-controls="`pack${p.id}`"
                    :aria-selected="p.id === calc.product_id"
                    @click="setProd(p.id)"
                  >
                    {{ p.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-9 tab-content" id="myTabContent">
              <div
                v-for="(p, i) in products"
                :key="i"
                class="tab-pane fade show"
                :class="{
                  active: p.id === calc.product_id
                }"
                :id="`pack${p.id}`"
                role="tabpanel"
                :aria-labelledby="`tab${p.id}`"
              >
                <div class="card card-bordered">
                  <div class="card-header">
                    <h5 class="card-title">
                      {{ p.name }}
                    </h5>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-5 form-group">
                      <label class="control-label">Realistic Yield/ha</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_yield"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-7"></div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Land Preparation</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.land_preparation"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label"
                        >Land Preparation Comment</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.land_preparation_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Planting Cost</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.planting_cost"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Planting Cost Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.planting_cost_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Harvesting</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.harvesting"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Harvesting Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.harvesting_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Seeds</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.seeds"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Seeds Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.seeds_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Chemical</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.chemical"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Chemical Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.chemical_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Fertilizer</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.fertilizer"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Fertilizer Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.fertilizer_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Weeding Cost</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.weeding_cost"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Weeding Cost Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.weeding_cost_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Spray</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.spray"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Spray Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.spray_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Irrigation</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.irrigation"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-7 form-group">
                      <label class="control-label">Irrigation Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.irrigation_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Misc</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.misc"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                    </div>
                    <div class="col-md-12 form-group">
                      <label class="control-label">Total Cost</label>
                      <input
                        readonly="readonly"
                        id="box_weight"
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.total_cost"
                      />
                    </div>
                    <div class="col-md-12 form-group">
                      <label class="control-label"
                        >Proposed Cost of Production</label
                      >
                      <input
                        readonly="readonly"
                        id="box_weight"
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].cost_of_production"
                      />
                    </div>
                    <div class="col-md-12 pt-5 form-group">
                      <button
                        type="submit"
                        class="btn btn-primary btn-rounded btn-block btn-lg mt-n1"
                      >
                        <span>
                          Save
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NgongVegBanner from "@/components/NgongVegBanner";
import { onMounted, reactive, ref } from "vue";
import http from "../../../../../common/services/http";
export default {
  name: "ProductionCost",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const form = ref({});

    const products = ref([]);

    const calc = reactive({
      product_id: 10,
      details: {
        realistic_yield: 0,
        land_preparation: 0,
        land_preparation_comment: "",
        planting_cost: 0,
        planting_cost_comment: "",
        harvesting: 0,
        harvesting_comment: "",
        seeds: 0,
        seeds_comment: "",
        chemical: 0,
        chemical_comment: "",
        fertilizer: 0,
        fertilizer_comment: "",
        weeding_cost: 0,
        weeding_cost_comment: "",
        spray: 0,
        spray_comment: "",
        irrigation: 0,
        irrigation_comment: "",
        misc: 0,
        total_cost: 0
      },
      cost_of_production: 0
    });

    function recalculateTotals(value: any, id: any) {
      const total =
        parseFloat(value.details.land_preparation) +
        parseFloat(value.details.planting_cost) +
        parseFloat(value.details.harvesting) +
        parseFloat(value.details.seeds) +
        parseFloat(value.details.chemical) +
        parseFloat(value.details.fertilizer) +
        parseFloat(value.details.weeding_cost) +
        parseFloat(value.details.spray) +
        parseFloat(value.details.irrigation) +
        parseFloat(value.details.misc);

      form.value[id].details.total_cost = total;
      form.value[id].cost_of_production =
        total / form.value[id].details.realistic_yield;
    }

    function calculateCostOfProduction(value: any) {
      if (value) {
        const total =
          parseFloat(value.details.land_preparation) +
          parseFloat(value.details.planting_cost) +
          parseFloat(value.details.harvesting) +
          parseFloat(value.details.seeds) +
          parseFloat(value.details.chemical) +
          parseFloat(value.details.fertilizer) +
          parseFloat(value.details.weeding_cost) +
          parseFloat(value.details.spray) +
          parseFloat(value.details.irrigation) +
          parseFloat(value.details.misc);

        return total / value.details.realistic_yield;
      } else {
        return 0;
      }
    }

    async function fetchCosts() {
      const res = await http.get(`/api/production-cost`);
      return res.data;
    }

    function fetchProducts() {
      processing.value = true;

      fetchCosts().then((costs: any) => {
        http.get(`/api/products?fetch=all`).then(res => {
          products.value = res.data;
          const pds = res.data;

          form.value = pds.reduce((r: any, v: any) => {
            return {
              ...r,
              [v.id]: {
                product_id: costs[v.id]?.product_id || v.id,
                details: costs[v.id]?.details || calc.details,
                cost_of_production: calculateCostOfProduction(costs[v.id])
              }
            };
          }, {});
          processing.value = false;
        });
      });
    }

    function saveProductionCost() {
      http
        .post("/api/production-cost", form.value)
        .then()
        .finally(() => {
          fetchProducts();
        });
    }

    function setProd(n: 0) {
      calc.product_id = n;
    }

    onMounted(() => {
      fetchProducts();
    });

    return {
      processing,
      products,
      calc,
      saveProductionCost,
      recalculateTotals,
      setProd,
      form
    };
  }
};
</script>

<style scoped></style>
