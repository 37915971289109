
import NgongVegBanner from "@/components/NgongVegBanner";
import { onMounted, reactive, ref } from "vue";
import http from "../../../../../common/services/http";
export default {
  name: "ProductionCost",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const form = ref({});

    const products = ref([]);

    const calc = reactive({
      product_id: 10,
      details: {
        realistic_yield: 0,
        land_preparation: 0,
        land_preparation_comment: "",
        planting_cost: 0,
        planting_cost_comment: "",
        harvesting: 0,
        harvesting_comment: "",
        seeds: 0,
        seeds_comment: "",
        chemical: 0,
        chemical_comment: "",
        fertilizer: 0,
        fertilizer_comment: "",
        weeding_cost: 0,
        weeding_cost_comment: "",
        spray: 0,
        spray_comment: "",
        irrigation: 0,
        irrigation_comment: "",
        misc: 0,
        total_cost: 0
      },
      cost_of_production: 0
    });

    function recalculateTotals(value: any, id: any) {
      const total =
        parseFloat(value.details.land_preparation) +
        parseFloat(value.details.planting_cost) +
        parseFloat(value.details.harvesting) +
        parseFloat(value.details.seeds) +
        parseFloat(value.details.chemical) +
        parseFloat(value.details.fertilizer) +
        parseFloat(value.details.weeding_cost) +
        parseFloat(value.details.spray) +
        parseFloat(value.details.irrigation) +
        parseFloat(value.details.misc);

      form.value[id].details.total_cost = total;
      form.value[id].cost_of_production =
        total / form.value[id].details.realistic_yield;
    }

    function calculateCostOfProduction(value: any) {
      if (value) {
        const total =
          parseFloat(value.details.land_preparation) +
          parseFloat(value.details.planting_cost) +
          parseFloat(value.details.harvesting) +
          parseFloat(value.details.seeds) +
          parseFloat(value.details.chemical) +
          parseFloat(value.details.fertilizer) +
          parseFloat(value.details.weeding_cost) +
          parseFloat(value.details.spray) +
          parseFloat(value.details.irrigation) +
          parseFloat(value.details.misc);

        return total / value.details.realistic_yield;
      } else {
        return 0;
      }
    }

    async function fetchCosts() {
      const res = await http.get(`/api/production-cost`);
      return res.data;
    }

    function fetchProducts() {
      processing.value = true;

      fetchCosts().then((costs: any) => {
        http.get(`/api/products?fetch=all`).then(res => {
          products.value = res.data;
          const pds = res.data;

          form.value = pds.reduce((r: any, v: any) => {
            return {
              ...r,
              [v.id]: {
                product_id: costs[v.id]?.product_id || v.id,
                details: costs[v.id]?.details || calc.details,
                cost_of_production: calculateCostOfProduction(costs[v.id])
              }
            };
          }, {});
          processing.value = false;
        });
      });
    }

    function saveProductionCost() {
      http
        .post("/api/production-cost", form.value)
        .then()
        .finally(() => {
          fetchProducts();
        });
    }

    function setProd(n: 0) {
      calc.product_id = n;
    }

    onMounted(() => {
      fetchProducts();
    });

    return {
      processing,
      products,
      calc,
      saveProductionCost,
      recalculateTotals,
      setProd,
      form
    };
  }
};
